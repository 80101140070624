const Logo = () => {
  return (
    <svg
      className="MuiBox-root css-1vjb0td"
      xmlns="http://www.w3.org/2000/svg"
      fill="#f07c30"
      viewBox="0 0 1025 194"
      stroke="currentColor"
    >
      <path d="M362.8 55.87C346.85 54.56 333.9 61.49 324.31 76.55L322.48 79.43L320.65 76.55C311.06 61.48 298.1 54.49 282.17 55.87C270.1 56.84 260.26 61.36 252.9 69.29C245 77.58 241 88.91 241 103V170.57H252.82V101.15C252.82 91.4 255.45 83.39 260.87 76.68C266.45 69.76 273.83 66.26 282.81 66.26C291.84 66.26 299.76 69.27 306.35 75.2C313.06 81.05 316.48 88.61 316.48 97.67V170.56H328.5V97.67C328.5 88.68 331.84 81.12 338.42 75.2C345.24 69.26 353.22 66.26 362.16 66.26C371.14 66.26 378.8 69.86 384.3 76.68C389.78 83.47 392.55 91.71 392.55 101.16V170.58H404.37V103.01C404.37 89.06 400.31 77.73 392.29 69.32C384.61 61.25 374.97 56.86 362.8 55.87Z"></path>
      <path d="M437.7 19H426.7V41.86H437.7V19Z"></path>
      <path d="M437.7 60.11H426.7V172.35H437.7V60.11Z"></path>
      <path d="M504.05 57.45C489.73 57.45 478.13 62.19 468.58 71.93C459.09 81.62 454.47 93.43 454.47 108.04V172.34H465.88V106.4C465.88 96.03 469.67 86.99 477.15 79.51C484.63 72.03 493.68 68.24 504.04 68.24C514.28 68.24 522.94 71.93 530.53 79.51C538.14 87.13 542.01 96.17 542.01 106.4V172.34H553.42V108.04C553.42 93.43 548.8 81.62 539.31 71.93C529.9 62.32 518.04 57.45 504.05 57.45Z"></path>
      <path d="M624.76 57.25C623.94 57.22 623.14 57.2 622.33 57.2C606.85 57.2 593.91 62.7 582.82 73.98C571.15 85.65 565.24 99.86 565.24 116.23C565.24 131.87 570.75 145.61 581.62 157.07C592.5 168.54 605.46 174.41 621.24 175.01C638.87 175.66 653.99 168.83 666.16 154.89L669.96 150.54V172.36H680.76V116.03C681.35 100.48 676.05 86.84 665.01 75.41C653.97 63.78 640.81 57.85 624.76 57.25ZM655.65 150.05C646.75 159.36 635.98 164.07 623.63 164.07C622.84 164.07 622.05 164.05 621.26 164.01C608.66 163.36 597.9 158.24 589.29 148.79C580.69 139.35 576.44 127.98 576.66 114.98C576.88 101.8 581.43 90.94 590.59 81.78C599.83 72.55 610.7 68.06 623.82 68.06C636.38 68.06 647.16 72.69 655.86 81.82C664.73 91.13 669.34 102.22 669.55 114.78C669.76 128.32 665.08 140.19 655.65 150.05Z"></path>
      <path d="M715.48 153.17C707.98 145.67 704.19 136.63 704.19 126.26V60.12H692.78V124.42C692.78 138.9 697.46 151.12 706.7 160.76C716.23 170.49 727.83 175.23 742.15 175.23C756.33 175.23 768.19 170.36 777.4 160.76C786.78 151.19 791.52 138.97 791.52 124.42V60.12H780.11V126.26C780.11 136.49 776.25 145.54 768.63 153.15C760.96 160.61 752.3 164.22 742.15 164.22C731.99 164.21 723.02 160.5 715.48 153.17Z"></path>
      <path d="M820.31 138.12V70.09H847.1V59.91H820.31V19.21H808.9V140.78C808.9 163.06 821.4 174.2 847.1 174.79V166.23C829.56 165.49 820.31 155.81 820.31 138.12Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M909.19 57.21C909.93 57.21 910.68 57.22 911.43 57.25C927.19 57.84 940.59 63.94 951.25 75.39C962.12 86.85 967.63 100.59 967.63 116.23C967.63 132.32 961.65 146.47 949.86 158.26C938.16 169.96 924.12 175.64 908.09 175.01C892.46 174.61 879.07 168.65 868.29 157.28C857.56 145.97 852.12 132.23 852.12 116.44C852.12 100.06 858.03 85.86 869.7 74.19C880.79 62.92 894.06 57.21 909.19 57.21ZM940.55 152.053C941.225 151.408 941.888 150.737 942.54 150.04C952.1 140.05 956.65 128.58 956.42 114.94C956 102.32 951.33 91.25 942.53 82.02C935.319 74.4529 926.721 69.977 916.94 68.6664V107.91C916.94 118.24 925.39 126.68 935.71 126.68H937.27C938.99 126.68 940.4 128.09 940.55 129.81V152.053ZM879.41 152.263C878.306 151.24 877.226 150.149 876.17 148.99C867.57 139.55 863.32 128.17 863.53 115.18C863.75 102 868.31 91.14 877.46 81.98C884.831 74.6092 893.271 70.1968 902.87 68.7675V107.91C902.87 118.23 894.43 126.68 884.1 126.68H882.54C880.82 126.68 879.41 128.09 879.41 129.81V152.263ZM896.75 162.252C892.819 158.675 890.35 153.518 890.35 147.79C890.35 137 899.11 128.24 909.9 128.24C920.69 128.24 929.45 137 929.45 147.79C929.45 153.591 926.922 158.801 922.91 162.381C919.014 163.506 914.897 164.07 910.57 164.07C909.77 164.07 908.96 164.05 908.14 164.01C904.128 163.877 900.337 163.294 896.75 162.252Z"
      ></path>
      <path d="M996.44 75.5L992.34 83.44V60.11H982.16V172.35H994.18V104.78C994.18 96.7 995.55 90.45 998.37 85.68C1000.24 82.65 1003.5 79.23 1008.07 75.49C1012.55 71.83 1018.19 69.71 1024.86 69.18V57.85C1019.87 58.01 1015 59.34 1010.05 61.9C1003.86 65.3 999.4 69.75 996.44 75.5Z"></path>
      <path d="M143.201 144.957C142.279 145.941 141.341 146.89 140.387 147.801V116.348C140.166 113.915 138.174 111.925 135.741 111.925H133.527C118.927 111.925 106.984 99.9821 106.984 85.3824V29.8481C120.822 31.701 132.982 38.0333 143.194 48.7421C155.64 61.7978 162.247 77.4622 162.84 95.3042C163.148 114.608 156.719 130.829 143.201 144.957Z"></path>
      <path d="M49.313 143.471C50.8078 145.111 52.3361 146.655 53.8993 148.102V116.348C53.8993 113.915 55.889 111.925 58.3219 111.925H60.5356C75.1353 111.925 87.0782 99.9821 87.0782 85.3824V29.9948C73.5013 32.0189 61.5684 38.2586 51.1461 48.6867C38.1988 61.634 31.7504 76.9973 31.4397 95.6439C31.1338 114.028 37.1485 130.121 49.313 143.471Z"></path>
      <path d="M69.3807 141.787C69.3807 149.877 72.8606 157.159 78.4039 162.216C83.4854 163.694 88.856 164.521 94.5406 164.711C95.6969 164.772 96.841 164.796 97.9804 164.796C104.107 164.796 109.936 163.996 115.451 162.402C121.114 157.338 124.681 149.976 124.682 141.787C124.682 126.522 112.296 114.136 97.0313 114.136C81.7667 114.136 69.3807 126.522 69.3807 141.787Z"></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194 97C194 150.572 150.572 194 97 194C43.4284 194 0 150.572 0 97C0 43.4284 43.4284 0 97 0C150.572 0 194 43.4284 194 97ZM99.1968 13.7109C98.137 13.67 97.0747 13.6507 96.0269 13.6507C74.6295 13.6507 55.8505 21.7226 40.1716 37.6712C23.664 54.1739 15.3055 74.2706 15.3055 97.4288C15.3055 119.761 23.0041 139.197 38.1819 155.201C53.432 171.283 72.3724 179.721 94.4756 180.284C117.142 181.171 137.013 173.135 153.556 156.591C170.237 139.91 178.697 119.903 178.697 97.1397C178.697 75.0173 170.902 55.5807 155.527 39.367C140.433 23.1775 121.488 14.5492 99.1968 13.7109Z"
      ></path>
    </svg>
  );
};

export default Logo;
